var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h5", { staticClass: "mb-3" }, [
      _vm.zip
        ? _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c(
                "CButton",
                {
                  attrs: { size: "sm", color: "info", disabled: _vm.zipAdded },
                  on: {
                    click: () => {
                      _vm.$emit("add:zip", _vm.zip)
                      this.zipAdded = true
                    },
                  },
                },
                [_c("CIcon", { attrs: { name: "cil-plus" } })],
                1
              ),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(" Zip code: "),
                _c("strong", [_vm._v(_vm._s(_vm.zip))]),
                _vm._v(", Locality: "),
                _c("strong", [_vm._v(_vm._s(_vm.locality))]),
                _vm._v(
                  " (~" +
                    _vm._s(_vm._f("FixedNumber")(_vm.haversineDistance)) +
                    " km) "
                ),
              ]),
            ],
            1
          )
        : _c("div", { staticClass: "py-1", attrs: { id: "msg" } }, [
            _vm._v("Click on the location where you want to get the zip code!"),
          ]),
    ]),
    _c("div", { attrs: { id: "map" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }